import { appBus } from '../main'

export const generalMixin = {
  data() {
    return {
//      isMobile: null,
//      browser: null,
      considerTrace: false,
      alwaysTrace: false,
      isLocalhost: window.location.host.indexOf('localhost') != -1,
      yesNoOptions: [
        {
          value: 1
          ,text: "Ja"
        }
        ,{
          value: 0
          ,text: "Nej"
        }
      ],
      formRules: {
        required: value => !!value.toString().trim() || 'Värde måste anges',
        checked(value, message = 'Val måste göras') {
//          console.log('formRules.checked', value)
//          console.log('formRules.checked typeof', typeof value)
          switch (typeof value) {
            case 'boolean':
              //single checkbox
              return value || message
            case 'string':
              //radio buttons
              return value.length !== 0 || message
          }
          //multiple checkboxes
          return value.length !== 0 || message
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ogiltig e-postadress'
        },
        personNo: value => {
          const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet ÅÅÅÅMMDDNNNN'
        },
        maxLength(value, maxLength) {
          return value.length <= maxLength || 'Max ' + maxLength + ' tecken'
        },
        positiveInteger(value, limitData) {
          let has_min = limitData && limitData.min !== undefined
          let has_max = limitData && limitData.max !== undefined
          if (value.toString().trim().length == 0) {
            return true
          }
          const pattern = /^[0-9]+$/;
          const isPositiveInteger = pattern.test(value.toString().trim())
          //is positive integer
          if (has_min && has_max) {
            //has both min and max
            if (!isPositiveInteger || value < limitData.min || value > limitData.max) {
              return 'Ange ett heltal mellan ' + limitData.min + ' och ' + limitData.max
            }
            return true
          }
          if (has_min) {
            //has only min
            if (!isPositiveInteger || value < limitData.min) {
              return 'Ange ett heltal som minst ' + limitData.min
            }
            return true
          }
          if (has_max) {
            //has only max
            if (!isPositiveInteger || value < limitData.max) {
              return 'Ange ett heltal som max ' + limitData.max
            }
            return true
          }
          if (!isPositiveInteger) {
            return 'Ange ett positivt heltal'
          }
          return true
        },
        organizationNumber: value => {
          const pattern = /^[0-9]{6}-[0-9]{4}$/
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet NNNNNN-NNNN'
        },
        domain: value => {
          const pattern = /^[0-9a-z-.]+$/
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ogiltigt domännamn'
        },
        date: value => {
          const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet ÅÅÅÅ-MM-DD'
        },
        time: value => {
          const pattern = /^[0-9]{2}:[0-9]{2}$/;
          return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet HH-MM'
        }
      }
    }
  },
  methods: {
    hasProperty(object, property) {
      return Object.prototype.hasOwnProperty.call(object, property)
    },
    /*
    getIsIOS() {
      let flag = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
      return flag
    },
    */
    isNumeric: function(value) {
      return value.toString().trim().length != 0 && !Number.isNaN(value)
    },
    /*
    getBrowser() {
      // Get the user-agent string 
      let userAgentString = navigator.userAgent
//      alert(userAgentString)
      // Detect Chrome 
      let chromeAgent = userAgentString.indexOf("Chrome") > -1
      // Detect Internet Explorer 
      let IExplorerAgent = userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1
      // Detect Firefox 
      let firefoxAgent = userAgentString.indexOf("Firefox") > -1
      // Detect Safari
      let safariAgent = userAgentString.indexOf("Safari") > -1
      // Discard Safari since it also matches Chrome 
      if ((chromeAgent) && (safariAgent)) {
        safariAgent = false
      }
      // Detect Opera 
      let operaAgent = userAgentString.indexOf("OP") > -1
      // Discard Chrome since it also matches Opera      
      if ((chromeAgent) && (operaAgent)) {
        chromeAgent = false
      }
      if (userAgentString.indexOf("CriOS") > -1) {
        //fix by kaxig :)
        return 'chrome'
      }
      if (IExplorerAgent) {
        return 'ie'
      } else if (safariAgent) {
        return 'safari'
      } else if (operaAgent) {
        return 'opera'
      } else if (firefoxAgent) {
        return 'firefox'
      }
      return 'chrome'
    },
    */
    getAbsUrl: function(relUrl) {
      let absUrl = window.location.origin + relUrl
      this.trace('getAbsUrl', absUrl)
      return absUrl
    },
    /*
    trace(a, b) {
      let has_b = b !== undefined
      if (this.considerTrace && (this.alwaysTrace || this.isMobileDevelopment)) {
        let output = a
        if (has_b) {
          output += ': ' + JSON.stringify(b)
        }
        this.$store.dispatch('prependTrace', output)
      }
      if (has_b) {
        console.log(a, b)
      } else {
        console.log(a)
      }
    },
    */
    generalParseErrorArray(error_ar, target = 'html') {
      let delimiter = '<br>'
      switch (target) {
        case 'js':
          delimiter = '/n'
        break
      }
      return error_ar.join(delimiter)
    },
    composeErrorMessage(res, defaultMessage) {
//      console.log('composeErrorMessage', res);
      return res.data.errors ? this.generalParseErrorArray(res.data.errors) : defaultMessage
    },
    onNonSuccessApiCall(res) {
      switch (res.status) {
        //unauthorized
        case 401:
          //logout
          this.$store.dispatch('logout')
          if (res.data.errors) {
            //alert error
            alert(this.generalParseErrorArray(res.data.errors, 'js'))
          }
          //go home
          this.$router.replace({ name: 'home' })
          return false
      }
      return true
    },
    displayAxiosCatchError(error) {
      let message
      if (error.response) {
        // Request made and server responded
        const responseData = error.response.data
        //now we can read the hardcoded message property
        message = responseData.message
        // console.log(error.response.status);
        // console.log(error.response.headers);
      // } else if (error.request) {
      //   console.log('error.request');
      //   // The request was made but no response was received
      //   console.log(error.request);
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   console.log('plain error', error.message);
      } else {
        message = error.toString()
        
      }
      appBus.$emit('message', message)
    }
  },
  created() {
//    this.isMobile = this.$isMobile()
//    this.isMobileDevelopment = this.isMobile && process.env.NODE_ENV != 'production'
//    this.isIOS = this.getIsIOS()
//    this.browser = this.getBrowser()
//    this.isSystemBrowser = this.isIOS ? this.browser === 'safari' : true
//    alert('browser: ' + this.browser)
//    alert('isSystemBrowser: ' + this.isSystemBrowser)
  }
}
